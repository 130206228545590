import { FaPeopleGroup } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AdminNavigation = () => {
    return (
        <div className="fixed bottom-0 p-2 flex border-t border-slate-400 w-full bg-white z-10">
            <div className="flex justify-center">
                <span className="px-1">
                    <Link to='/weddingguests'><FaPeopleGroup size={25} /></Link>
                </span>
            </div>
        </div>
    )
}

export default AdminNavigation