import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const Layout = () =>{
    return (
        <div className="min-h-full">
            <ToastContainer/>
            <Outlet/>
        </div>
    )
}

export default Layout