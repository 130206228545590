export const ValidatedPhonenumber = (value : any): boolean=>{
    if(isNaN(value)){
        return true;
    }

    const regex = /^0\d{9}$/;

    if(!regex.test(value.toString())){
        return true
    }
    
    return false;
}