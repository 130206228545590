import CryptoJS from "crypto-js";

export const encrypt = (data: string): string => {
    const key = process.env.REACT_APP_SECRETKEY as string
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), { iv }).toString();
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

    return (`${ivBase64}:${encrypted}`);
}

export const decrypt = (encryptedData: string): string => {
    // Extract IV and encrypted data from the input string
    const [ivBase64, encrypted] = encryptedData.split(':');

    // Decode the IV from Base64
    const iv = CryptoJS.enc.Base64.parse(ivBase64);

    // Get the key from environment variable
    const key = process.env.REACT_APP_SECRETKEY as string;

    // Parse the key
    const keyHex = CryptoJS.enc.Utf8.parse(key);

    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(encrypted, keyHex, { iv });
    
    // Convert decrypted data to string
    return decrypted.toString(CryptoJS.enc.Utf8);
}