import { Navigate, Outlet } from "react-router-dom"
import AdminNavigation from "./AdminNavigation";
import { ToastContainer } from "react-toastify";
import { useAuth } from "utility/AuthProvide";

const SecureLayout = () => {
    const user = useAuth();
    if (!user.token) return <Navigate to="/login" />;
    
    return (
        <div className="relative">
            <AdminNavigation />
            <main>
                <Outlet />
            </main>
            <ToastContainer />
        </div>
    )
}

export default SecureLayout;