import { ImSpinner11 } from "react-icons/im";

const Loader = () =>{
    return (
        <div className="fixed bg-black bg-opacity-45 backdrop-blur-md min-h-screen flex justify-center align-middle z-50 w-full">
            <div className="text-white mt-56">
                <ImSpinner11 size={40} className="animate-spin"/>
                <p>Loading...</p>
            </div>
        </div>
    )
}

export default Loader