import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'pages/public/Layout';
import WhiteWedding from 'pages/public/white_wedding';
import Rsvp from 'pages/public/rsvp';
import SecureLayout from 'pages/secure/Layout';
import Admin from 'pages/secure/Admin';
import WeddingGuests from 'pages/secure/WeddingGuests';
import Login from 'pages/public/Login';
import AuthProvider from 'utility/AuthProvide';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/' element={<Layout />}>
            <Route path=':id' element={<WhiteWedding />}></Route>
            <Route path='' element={<Rsvp />}></Route>
          </Route>
          <Route path='/' element={<SecureLayout />}>
            <Route path='/admin' element={<Admin />}></Route>
            <Route path='/weddingguests' element={<WeddingGuests />}></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>

  );
}

export default App;
