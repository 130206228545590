import InputCheckBox from "components/inputCheckbox";
import InputText from "components/inputText";
import Loader from "components/loading";
import { encrypt } from "lib/encryption";
import { ValidatedPhonenumber } from "lib/validations";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Rsvp = () => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FieldValues>();
    const [thankFeedback, setThankFeedback] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (data: FieldValues) => {
        setValue('AllowedPlusOne', true);
        setValue('Confirmation', true);
        setValue('ContactNumber', 'default');
        setValue('Declined', false);
        setValue('InviteUrl', '');
        setValue('OriginalUrl', '');
        setValue('WeddingDay', 2)
        setLoading(true);

        const method = 'POST';
        const url = 'https://weddingserver.koswell.co.za/api/v1/guest/create/'

        const dataObj = {
            Data: encrypt(JSON.stringify(data))
        }

        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        })

        const result = await response.json();
        setLoading(false);
        if (!response.ok) {
            toast.error(result.message);
            return;
        }
        setThankFeedback(true);

        toast.success('Saved');
    }

    if (thankFeedback) {
        return (
            <div className="relative bg-cover bg-center bg-slate-500 min-h-screen background-3">
                <div className="bg-white bg-opacity-65 min-h-screen px-3 text-center pt-10">

                    <p className=" text-base text-black font-bold">Thank you for your confirmation, we look forward to sharing this
                        special moment with you</p>
                    <p className="bodoni-moda-option-1 font-extrabold">See you there</p>
                </div>
            </div>
        )
    }

    return (
        <div className="relative bg-cover bg-center bg-slate-500 min-h-screen background-3">
            {loading && <Loader />}
            <form onSubmit={handleSubmit(submit)}>
                <div className="bg-white bg-opacity-65 min-h-screen px-3">
                    <div className="mx-auto h-full">
                        <h2 className="font-semibold bodoni-moda-option-1 py-4 text-center text-xl">RSVP Here</h2>
                    </div>
                    <span className="py-3">
                        <InputText register={register}
                            fieldName="Name"
                            placeHolder="Name"
                            errors={errors}
                            validation={{
                                required: 'This field is required',

                            }}
                        />
                    </span>

                    <span className="py-3">
                        <InputText register={register}
                            fieldName="Surname"
                            placeHolder="Surname"
                            errors={errors}
                            validation={{
                                required: 'This field is required',

                            }}
                        />
                    </span>

                    <InputCheckBox register={register} fieldName="PlusOne" placeHolder="Coming with a plus one?" errors={errors} />

                    <div className="flex flex-col mt-3 gap-3">
                        <button type="submit" onClick={() => { }} className="bg-black text-white w-full rounded-full py-2 bodoni-moda-option-1 ">Confirm</button>
                    </div>
                </div >
            </form>
        </div>

    )
}

export default Rsvp;