import { FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
import { FaPhone } from "react-icons/fa";

interface Props {
    fieldName: string,
    placeHolder: string,
    type?: string,
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    validation?: RegisterOptions
}

const InputText = ({ fieldName, placeHolder, register, errors, validation, type }: Props) => {
    const errorMessage = errors[fieldName]?.message as string | undefined;
    return (
        <div className=" flex flex-col">
            <label className={`pl-3 ${errors[fieldName] ? 'text-red-500' : ''} `}>{placeHolder}</label>
            <input type={type ? type : 'text'} {...register(fieldName, validation)}
                className={`border border-black rounded-full p-2 bg-transparent w-full font-black px-2 ${errors[fieldName] ? 'border-red-500' : ''}`}
                placeholder={placeHolder}></input>
            {errors[fieldName] && <p className="text-xs text-red-500 pl-3">{errorMessage}</p>}
        </div>
    )
}

export default InputText