import InputCheckBox from "components/inputCheckbox";
import InputText from "components/inputText";
import Timer from "components/timer";
import { ValidatedPhonenumber } from "lib/validations";
import { useEffect, useRef, useState } from "react"
import { FieldValues, useForm } from "react-hook-form";
import { FaArrowRight, FaPhone } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { decrypt } from "lib/encryption";
import Loader from "components/loading";

const WhiteWedding = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [Guest, setGuestDetails] = useState<any>();
    const [isPlaying, setIsPlaying] = useState(false);
    const { id } = useParams();
    const [guestId, setGuestId] = useState<number>()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FieldValues>();
    const secretKey = CryptoJS.enc.Utf8.parse('drfeosgEQYq8inDFY0+fc8kEQUoi6zSu');
    const audioRef = useRef<HTMLAudioElement>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (id) {
            LoadData(id);            
        }
    }, [])

    const LoadData = async (Id: string) => {

        const response = await fetch(`https://weddingserver.koswell.co.za/api/v1/guest/extension/${Id}`);
        const data = await response.json();
        if (!response.ok) {
            toast.error(data.message);
            return;
        }

        const result = JSON.parse(decrypt(data?.result));
        setGuestDetails(result);
        setGuestId(result.id);
        setValue('AllowedPlusOne', result.AllowedPlusOne);
        setValue('Confirmation', result.Confirmation);
        setValue('Declined', result.Declined);
        setValue('InviteUrl', result.InviteUrl);
        setValue('Name', result.Name);
        setValue('OriginalUrl', result.OriginalUrl);
        setValue('PlusOne', result.PlusOne);
        setValue('Surname', result.Surname);
        setValue('WeddingDay', result.WeddingDay);
    }

    const encrypt = (data: string): string => {

        const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
        const encrypted = CryptoJS.AES.encrypt(data, secretKey, { iv }).toString();
        const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

        return (`${ivBase64}:${encrypted}`);
    }

    const submit = async (data: FieldValues) => {
        await updateInvite(data);
    }

    const decline = async () => {
        const data = {...Guest, Declined : true}
        
        await updateInvite(data);
    }

    const updateInvite = async (data: any) => {
        setLoading(true);
        const dataObj = {
            Data: encrypt(JSON.stringify(data))
        }

        const response = await fetch(`https://weddingserver.koswell.co.za/api/v1/guest/update/${guestId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObj)
        })
        setLoading(false);
        if (!response.ok) {
            const result = await response.json();

            toast.error(result.message);
            return
        }
     
        toast.success('Response processed');
        setGuestDetails(data);
        if(data?.Declined){
            setCurrentStep(1)
            return
        }

        setCurrentStep(4);
    }

    const handlePlay = () => {
        setCurrentStep(2)
        const audio = audioRef.current;
        if (audio) {
            audio.play().then(() => {
                setIsPlaying(true);
            }).catch(error => {
                console.error('Autoplay was prevented:', error);
            });
        }
    };

    const _renderView = () => {
        switch (currentStep) {
            case 1:
                return <div className="absolute bottom-0">
                    <div className="text-white p-3">
                        <p className="text-2xl text-white leading-none bodoni-moda-option-1">Thabiso Nyathela &<br />Lerato Motlhabi</p>
                        {Guest && <button onClick={handlePlay} className="float-end bg-white rounded-full p-2  animate-bounce"><img
                            className="w-7 inline-block" src={process.env.PUBLIC_URL + 'rings.png'} alt=""></img>
                            <span className="inline-block text-black "><FaArrowRight /></span></button>}
                        <div className="border-t border-white w-2/3 my-3"></div>
                        <p className="text-sm text-white leading-none bodoni-moda-option-1">14TH DECEMBER 2024</p>
                    </div>
                </div>
            case 2:
                return (
                    <div className="bg-cover min-h-screen background-2 bg-center">
                        <div className="text-center">
                            <h2 className="dancing-script-curvise pt-6 text-2xl font-extrabold">Wedding Invitation</h2>
                            <p className="text-sm leading-none py-4 mx-9">We would love your presence in celebrating our love story</p>
                            <Timer />
                        </div>
                        <div className="bg-white mt-4 text-center py-3">
                            <h1 className="text-6xl bodoni-moda-option-1">T</h1>
                            <p className="justify-center content-center pt-4"><img src={process.env.PUBLIC_URL + 'matrimonial.png'}
                                className="w-12 block ml-auto mr-auto" alt=""></img></p>
                            <h2 className="font-semibold py-1">Matrimonial</h2>
                            <p className="text-sm leading-none">North West</p>
                            <p className="text-sm leading-snug">The celebration will be there</p>
                            <div className="flex flex-row pt-3">
                                <div className="w-1/2 border-r border-black h-10">
                                    <p className="font-bold pt-2">14 Dec</p>
                                </div>
                                <div className="w-1/2">
                                    <p className="font-bold pt-2">11:00 am</p>
                                </div>
                            </div>
                            <p className="justify-center content-center pt-6"><img src={process.env.PUBLIC_URL + 'reception.png'}
                                className="w-12 block ml-auto mr-auto" alt=""></img></p>
                            <h2 className="font-semibold py-1">Reception</h2>
                            <p className="text-sm leading-none">North West</p>
                            <p className="text-sm leading-snug">The celebration will be there</p>
                            <div className="flex flex-row pt-3">
                                <div className="w-1/2 border-r border-black h-10 ">
                                    <p className="font-bold pt-2">14 Dec</p>
                                </div>
                                <div className="w-1/2">
                                    <p className="font-bold pt-2">14:00 pm</p>
                                </div>
                            </div>
                            <p className="justify-center content-center pt-6"><img src={process.env.PUBLIC_URL + 'dresscode.png'}
                                className="w-12 block ml-auto mr-auto" alt=""></img></p>
                            <h2 className="font-semibold py-1">Dress Code</h2>
                            <p className="text-sm leading-none">Black Tie</p>
                            <h1 className="text-6xl bodoni-moda-option-1 pt-6">L</h1>
                            <div className="px-3 flex flex-row flex-wrap">
                                <div className="w-1/2">
                                    <div className="h-44 p-1">
                                        <img src={process.env.PUBLIC_URL + 'images/PhotoA.jpg'} className="h-full w-full" alt="Photo"></img>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="h-44 p-1">
                                        <img src={process.env.PUBLIC_URL + 'images/PhotoC.jpg'} className="h-full w-full" alt="Photo"></img>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="h-40 p-1">
                                        <img src={process.env.PUBLIC_URL + 'images/PhotoD.jpg'} className="w-full h-full" alt="Photo"></img>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="h-40 p-1">
                                        <img src={process.env.PUBLIC_URL + 'images/PhotoE.jpg'} className="w-full h-full" alt="Photo"></img>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-transparent flex flex-col gap-3 p-3">
                            {!Guest?.Declined && <button className="border border-black text-black w-full py-2 rounded-full bodoni-moda-option-1" onClick={decline}>Decline Invite</button>}
                            {(Guest?.Confirmation && !Guest.Declined) && <button className="bg-black text-white w-full rounded-full py-2 bodoni-moda-option-1" onClick={() =>setCurrentStep(4)}>View Details</button>}
                            {(!Guest?.Confirmation && !Guest.Declined) && <button className="bg-black text-white w-full rounded-full py-2 bodoni-moda-option-1" onClick={() => { setCurrentStep(3); setValue('Confirmation', true) }}>Accept Invite</button>}
                        </div>
                    </div>
                )
            case 3:
                return (
                    <form onSubmit={handleSubmit(submit)}>
                        {loading && <Loader/>}
                        <div className="bg-white bg-opacity-65 min-h-screen px-3">
                            <div className="mx-auto h-full py-5">
                                <h2 className="font-semibold bodoni-moda-option-1 py-4 text-center text-xl">Confirm Details</h2>
                            </div>
                            <InputText register={register}
                                fieldName="ContactNumber"
                                placeHolder="Phone Number"
                                errors={errors}
                                validation={{
                                    required: 'This field is required',
                                    validate: (value) => {
                                        if (ValidatedPhonenumber(value)) {
                                            return 'Invalid phone number format 0xxxxxxxxx'
                                        }
                                    }
                                }}
                            />
                            {Guest?.AllowedPlusOne && <InputCheckBox register={register} fieldName="PlusOne" placeHolder="Coming with a plus one?" errors={errors} />}
                            <p className="dancing-script-curvise text-md text-center font-extrabold">Please note no children allowed
                            </p>
                            <div className="flex flex-col mt-3 gap-3 py-3">
                                <button type="submit" onClick={() => { }} className="bg-black text-white w-full rounded-full py-2 bodoni-moda-option-1 ">Confirm</button>
                                <button type="button" onClick={() => setCurrentStep(2)} className="border border-black w-full rounded-full py-2 bodoni-moda-option-1 ">Back</button>
                            </div>
                        </div >
                    </form>

                )

            case 4:
                return (
                    <div className="bg-white h-full text-center px-3">
                        <h2 className="dancing-script-curvise pt-6 text-2xl font-extrabold">Hey</h2>
                        <p className="bodoni-moda-option-1 text-xl">{Guest?.Name} {Guest?.Surname}</p>
                        <p className="bodoni-moda-option-1 text-base">Thank you for your confirmation, we look forward to sharing this
                            special moment with you</p>
                        <p className="dancing-script-curvise text-xl mt-4">Wedding Venue</p>
                        <p className="bodoni-moda-option-1 text-md">Oryx Hill Safari Lodge <br /> Ottosdal</p>
                        <p className="py-4"><img src={process.env.PUBLIC_URL + 'images/OIP.png'} className="animate-pulse w-20  block ml-auto mr-auto" alt=""></img></p>
                        <p className="bodoni-moda-option-1 text-md">We would apprieciate vouchers from these selected stores</p>
                        <p className="justify-center content-center pt-6"><img src={process.env.PUBLIC_URL + 'images/home.jpg'}
                            className="w-28 p-1 inline-block ml-auto mr-auto " alt=""></img><img src={process.env.PUBLIC_URL + 'images/mrprice.jpg'}
                                className="w-28 p-1 inline-block ml-auto mr-auto" alt=""></img><img src={process.env.PUBLIC_URL + 'images/woolworths.jpg'}
                                    className="w-24 h-20 py-1 inline-block ml-auto mr-auto " alt=""></img></p>
                        <p className="py-2 "><button type="button"
                            className="bg-black text-white w-full rounded-full py-2 bodoni-moda-option-1" onClick={() => setCurrentStep(2)}>Done</button></p>

                    </div>
                )
        }
    }
    return (
        <div className="relative bg-cover bg-center bg-slate-500 min-h-screen background" >
            <audio ref={audioRef} loop>
                <source src={process.env.PUBLIC_URL + 'First_Time.mp3'} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            {_renderView()}
        </div>
    )
}

export default WhiteWedding


