import InputText from "components/inputText";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { useAuth } from "utility/AuthProvide";

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<FieldValues>();
    const { loginAction } = useAuth();

    const Submit = async (data: FieldValues) => {
        await loginAction(data);
        
    };

    return (
        <div className="min-h-screen flex justify-center align-middle bg-cover bg-center background-4 pt-20 w-full">
            <ToastContainer/>
            <form className="w-11/12" onSubmit={handleSubmit(Submit)}>
                <div className="flex flex-col">
                    <div className="bg-slate-500 text-white text-center text-sm p-2">
                        <h3>Login</h3>
                    </div>
                    <div className="h-min p-2 bg-white flex flex-col gap-3 bg-opacity-65">
                        <InputText
                            fieldName="Email"
                            placeHolder="Email Address"
                            register={register}
                            errors={errors}
                            validation={{
                                required: 'This field is required'
                            }}
                        />
                        <InputText
                            fieldName="Password"
                            placeHolder="Password"
                            register={register}
                            type="password"
                            errors={errors}
                            validation={{
                                required: 'This field is required'
                            }}
                        />
                        <button type="submit" className="bg-slate-500 p-2 w-full rounded-full text-white">Login</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
