interface Props{
    onChange? : any
}

const SearchBoxField = ({onChange}: Props) =>{

    return (
        <div className="w-full">
            <input type="text" className="rounded-full w-full border border-slate-300 p-2" placeholder="Search name here..." onChange={onChange}/>    
        </div>
    )
}

export default SearchBoxField