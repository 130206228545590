import Countdown, { CountdownRendererFn } from "react-countdown";

const Timer = () => {
    const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Time's up!</span>;
        } else {
            return (

                <div className="flex flex-row justify-center text-center ml-5">
                    <div className="w-1/4">
                        <p className="bg-black text-white rounded-full w-10 h-10 pt-2">{days}</p>
                        <p className="text-left pl-1">days</p>
                    </div>
                    <div className="w-1/4">
                        <p className="bg-black text-white rounded-full w-10 h-10 pt-2">{hours}</p>
                        <p className="text-left pl-2">hrs</p>
                    </div>
                    <div className="w-1/4">
                        <p className="bg-black text-white rounded-full w-10 h-10 pt-2">{minutes}</p>
                        <p className="text-left pl-1">mins</p>
                    </div>
                    <div className="w-1/4">
                        <p className="bg-black text-white rounded-full w-10 h-10 pt-2">{seconds}</p>
                        <p className="text-left pl-2">sec</p>
                    </div>
                </div>

            );
        }
    };

    return <div>
        <Countdown date={new Date('2024-12-14T11:00:00')} renderer={renderer} />
    </div>
}

export default Timer